import { Trans } from 'next-i18next';
import Link from 'next/link';

const mediaSourceSize = { width: 2384, height: 1826 };

export const presentationFeatures = [
  {
    header: 'home.featuresPresentation.features.first.header',
    description: 'home.featuresPresentation.features.first.description',
    descriptionList: [
      'home.featuresPresentation.features.first.descriptionList.1',
      'home.featuresPresentation.features.first.descriptionList.2',
      'home.featuresPresentation.features.first.descriptionList.3',
      'home.featuresPresentation.features.first.descriptionList.4',
    ],
    footer: 'home.featuresPresentation.features.first.footer',
    mediaSource: {
      path: '/img/smComputingScreens/computing-browse.webp',
      description: 'home.featuresPresentation.features.first.alt',
      size: mediaSourceSize,
    },
    isGlowingSquareVisible: false,
  },
  {
    header: 'home.featuresPresentation.features.second.header',
    description: 'home.featuresPresentation.features.second.description',
    descriptionList: [
      'home.featuresPresentation.features.second.descriptionList.1',
      'home.featuresPresentation.features.second.descriptionList.2',
      'home.featuresPresentation.features.second.descriptionList.3',
      'home.featuresPresentation.features.second.descriptionList.4',
      'home.featuresPresentation.features.second.descriptionList.5',
      'home.featuresPresentation.features.second.descriptionList.6',
    ],
    mediaSource: {
      path: '/img/smComputingScreens/computing-templates.webp',
      description: 'home.featuresPresentation.features.second.alt',
      size: mediaSourceSize,
    },
    isGlowingSquareVisible: true,
    glowingSquarePosition: {
      positionHorizontal: 'right',
      positionVertical: 'top',
    },
  },
  {
    header: 'home.featuresPresentation.features.third.header',
    description: 'home.featuresPresentation.features.third.description',
    descriptionList: [
      'home.featuresPresentation.features.third.descriptionList.1',
      'home.featuresPresentation.features.third.descriptionList.2',
      'home.featuresPresentation.features.third.descriptionList.3',
      'home.featuresPresentation.features.third.descriptionList.4',
    ],
    footer: 'home.featuresPresentation.features.third.footer',
    mediaSource: {
      path: '/img/smComputingScreens/computing-choose.webp',
      description: 'home.featuresPresentation.features.third.alt',
      size: mediaSourceSize,
    },
    isGlowingSquareVisible: false,
  },
  {
    header: 'home.featuresPresentation.features.fourth.header',
    description: 'home.featuresPresentation.features.fourth.description',
    descriptionList: [
      'home.featuresPresentation.features.fourth.descriptionList.1',
      'home.featuresPresentation.features.fourth.descriptionList.2',
      'home.featuresPresentation.features.fourth.descriptionList.3',
    ],
    mediaSource: {
      path: '/img/smComputingScreens/computing-monitor.webp',
      description: 'home.featuresPresentation.features.fourth.alt',
      size: mediaSourceSize,
    },
    isGlowingSquareVisible: false,
  },
  {
    header: 'home.featuresPresentation.features.fifth.header',
    description: 'home.featuresPresentation.features.fifth.description',
    descriptionList: [
      'home.featuresPresentation.features.fifth.descriptionList.1',
      'home.featuresPresentation.features.fifth.descriptionList.2',
      'home.featuresPresentation.features.fifth.descriptionList.3',
    ],
    footer: 'home.featuresPresentation.features.fifth.footer',
    mediaSource: {
      path: '/img/smComputingScreens/computing-manage.webp',
      description: 'home.featuresPresentation.features.fifth.alt',
      size: mediaSourceSize,
    },

    isGlowingSquareVisible: true,
    glowingSquarePosition: {
      positionHorizontal: 'left',
      positionVertical: 'top',
    },
  },
  {
    header: 'home.featuresPresentation.features.sixth.header',
    description: 'home.featuresPresentation.features.sixth.description',
    descriptionList: [
      'home.featuresPresentation.features.sixth.descriptionList.1',
      'home.featuresPresentation.features.sixth.descriptionList.2',
      'home.featuresPresentation.features.sixth.descriptionList.3',
    ],
    footer: 'home.featuresPresentation.features.sixth.footer',
    mediaSource: {
      path: '/img/smComputingScreens/computing-jupyter.webp',
      description: 'home.featuresPresentation.features.fifth.alt',
      size: mediaSourceSize,
    },

    isGlowingSquareVisible: false,
  },
  {
    header: 'home.featuresPresentation.features.seventh.header',
    description: 'home.featuresPresentation.features.seventh.description',
    descriptionList: [
      'home.featuresPresentation.features.seventh.descriptionList.1',
      'home.featuresPresentation.features.seventh.descriptionList.2',
      'home.featuresPresentation.features.seventh.descriptionList.3',
      'home.featuresPresentation.features.seventh.descriptionList.4',
    ],
    footer: (
      <Trans i18nKey="home.featuresPresentation.features.seventh.footer">
        Missing a feature?
        <Link
          className="pricing-examples__element-message-link link-type-underline"
          href="/support"
        >
          Let us know
        </Link>
        !
      </Trans>
    ),
    mediaSource: {
      path: '/img/smComputingScreens/computing-coming-soon.webp',
      description: 'home.featuresPresentation.features.seventh.alt',
      size: mediaSourceSize,
    },
    isGlowingSquareVisible: false,
  },
];
